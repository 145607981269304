<template>
    <b-container class="py-5 text-left">
        <h1 class="mb-5">Aviso de Privacidad</h1>
        <p>
            Delivering Geniality SAPI de CV, en lo sucesivo “Zubut”, hace de su conocimiento y le
            informa que la totalidad de los datos personales que ha proporcionado, aún aquellos que
            sean considerados como sensibles, referidos en los documentos requisitados por usted con
            motivo de la relación jurídica, comercial, contable o de información, que se tiene
            celebrada, se tratarán para identificación, operación, administración, comercialización
            y otros fines análogos, la empresa es responsable de la protección y uso de los mismos,
            pudiendo transferirse a entidades de Zubut, integrantes de la Corporación, Subsidiarias,
            Afiliadas o en su caso a terceros nacionales o extranjeros, por lo que si no manifiesta
            su oposición, se entenderá que ha otorgado su consentimiento.
        </p>
        <p>
            La información personal será resguardada por el Departamento de Datos Personales de
            Zubut, (o en su caso persona designada para ello), ante quien puede ejercer sus derechos
            de acceso, rectificación, cancelación y oposición, mediante solicitud por escrito que
            presente en el domicilio antes indicado.
        </p>
        <p>
            Zubut implementará todas las medidas posibles para mantener la confidencialidad y la
            seguridad de la información del Usuario, pero no responderá por los daños o perjuicios
            que pudieran derivarse de la violación de esas medidas por parte de terceros que
            utilicen las redes públicas o Internet, alterando los sistemas de seguridad para obtener
            acceso a la información de los Usuarios.
        </p>
        <p>
            Sin perjuicio de lo anterior, el uso de la Aplicación y del Sitio se encuentran
            condicionados a la lectura y aceptación por parte del Usuario de la Política de
            Privacidad, la cual se encuentra a su disposición tanto en la Aplicación como en el
            Sitio, por lo que en caso de que el Usuario continúe utilizando la Aplicación o el
            Sitio, el Usuario manifiesta su conformidad con dicha Política de Privacidad.
        </p>
        <p>Todos los derechos reservados, 2021</p>
        <p>Zubut®</p>
        <p>Delivering Geniality SAPI de CV</p>
    </b-container>
</template>

<script>
export default {
    name: "PrivacyPolicy",
    metaInfo: {
        title: "Aviso de privacidad ",
        meta: [
            {
                name: "description",
                content: "Aviso de privacidad de Zubut"
            }
        ]
    }
};
</script>
